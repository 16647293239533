import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LogoBlack from "../../assets/logo/sim-broadcasts-logo-t-b.png";

const StyledDrawer = styled.div`
  position: fixed;
  overflow: auto;
  z-index: 3;
  top: 0px;
  right: -350px;
  width: 350px;
  height: 100%;
  transition: right 0.2s ease-in-out;
  background-color: #fff;

  &.active {
    right: 0px;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    opacity: 0;

    &:hover {
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.1);
    }

    &:horizontal {
      height: 8px;
    }

    &:vertical {
      width: 8px;
    }
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece {
  }
  &::-webkit-resizer {
  }

  @media (max-width: 675px) {
    width: 300px;
  }

  @media (prefers-color-scheme: dark) {
    background-color: #1a1a1a;
  }
`;

const DrawerHeader = styled.div`
  box-sizing: border-box;
  height: 110px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 500px) {
    height: 80px;
    padding: 10px 10px 10px 25px;
  }
`;

const DrawerClose = styled.a`
  display: block;
  height: 40px;
  width: 40px;
  color: #000;
  line-height: 40px;
  text-align: center;
  font-size: 1.5em;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const DrawerItems = styled.nav`
  box-sizing: border-box;
  padding: 10px 0px;
  clear: both;
`;

const DrawerItem = styled(Link)`
  box-sizing: border-box;
  padding: 5px 20px;
  display: block;
  width: 100%;
  line-height: 40px;
  color: #000;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
`;

const DrawerItemIcon = styled.i`
  width: 35px;
  text-align: center;
  color: #000;

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const DrawerItemA = styled.a`
  box-sizing: border-box;
  padding: 5px 20px;
  display: block;
  width: 100%;
  line-height: 40px;
  color: #000;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
`;

const DrawerItemText = styled.span`
  margin-left: 20px;
  color: #000;

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const Logo = styled.div`
  background-image: url(${LogoBlack});
  width: 50px;
  height: 30px;
  background-size: 250px;
  background-repeat: no-repeat;
`;

const Divider = styled.div`
  border-top: solid 2px #d5d5d5;
  margin: 20px;

  @media (max-width: 900px) {
    margin: 10px 20px;
  }

  @media (prefers-color-scheme: dark) {
    border-color: #444;
  }
`;

const Drawer = (props) => {
  return (
    <StyledDrawer className={props.drawerOpen ? "active" : null}>
      <DrawerHeader>
        <Logo />
        <DrawerClose onClick={props.actions.toggleDrawer}>
          <i className="far fa-times" />
        </DrawerClose>
      </DrawerHeader>
      <DrawerItems>
        <DrawerItem key="dr_home" to="/" onClick={props.actions.toggleDrawer}>
          <DrawerItemIcon className="far fa-home" />
          <DrawerItemText>Home</DrawerItemText>
        </DrawerItem>

        <DrawerItem
          key="dr_about"
          to="/about"
          onClick={props.actions.toggleDrawer}
        >
          <DrawerItemIcon className="far fa-question-circle" />
          <DrawerItemText>About Us</DrawerItemText>
        </DrawerItem>

        <DrawerItem
          key="dr_calendar"
          to="/calendar"
          onClick={props.actions.toggleDrawer}
        >
          <DrawerItemIcon className="far fa-calendar" />
          <DrawerItemText>Calendar</DrawerItemText>
        </DrawerItem>

        <DrawerItem
          key="dr_bookings"
          to="/bookings"
          onClick={props.actions.toggleDrawer}
        >
          <DrawerItemIcon className="far fa-calendar-check" />
          <DrawerItemText>Request Your Broadcast</DrawerItemText>
        </DrawerItem>

        <DrawerItem
          key="dr_supporters"
          to="/supporters"
          onClick={props.actions.toggleDrawer}
        >
          <DrawerItemIcon className="far fa-star" />
          <DrawerItemText>Supporters Club</DrawerItemText>
        </DrawerItem>

        {/* <DrawerItem
          key="dr_shop"
          to="/shop"
          onClick={props.actions.toggleDrawer}
        >
          <DrawerItemIcon className="far fa-store" />
          <DrawerItemText>Shop</DrawerItemText>
        </DrawerItem> */}

        <DrawerItem
          key="dr_corporate"
          to="/corporate"
          onClick={props.actions.toggleDrawer}
        >
          <DrawerItemIcon className="far fa-building" />
          <DrawerItemText>Corporate Services</DrawerItemText>
        </DrawerItem>

        <DrawerItem
          key="dr_join"
          to="/join"
          onClick={props.actions.toggleDrawer}
        >
          <DrawerItemIcon className="far fa-briefcase" />
          <DrawerItemText>Join Our Team</DrawerItemText>
        </DrawerItem>

        <DrawerItem
          key="dr_voice"
          to="/voice"
          onClick={props.actions.toggleDrawer}
        >
          <DrawerItemIcon className="far fa-user-headset" />
          <DrawerItemText>Voice Server</DrawerItemText>
        </DrawerItem>

        <DrawerItem
          key="dr_media"
          to="/media"
          onClick={props.actions.toggleDrawer}
        >
          <DrawerItemIcon className="far fa-paint-brush" />
          <DrawerItemText>Media Pack</DrawerItemText>
        </DrawerItem>

        <Divider />

        <DrawerItemA key="dr_twitch" href="https://twitch.tv/simbroadcasts">
          <DrawerItemIcon className="fab fa-twitch" />
          <DrawerItemText>Twitch</DrawerItemText>
        </DrawerItemA>

        <DrawerItemA
          key="dr_youtube"
          href="https://www.youtube.com/simbroadcasts"
        >
          <DrawerItemIcon className="fab fa-youtube" />
          <DrawerItemText>YouTube</DrawerItemText>
        </DrawerItemA>

        <DrawerItemA
          key="dr_facebook"
          href="https://facebook.com/simbroadcasts"
        >
          <DrawerItemIcon className="fab fa-facebook-square" />
          <DrawerItemText>Facebook</DrawerItemText>
        </DrawerItemA>

        <DrawerItemA key="dr_twitter" href="https://twitter.com/simbroadcasts">
          <DrawerItemIcon className="fab fa-twitter" />
          <DrawerItemText>Twitter</DrawerItemText>
        </DrawerItemA>

        <DrawerItemA key="dr_messenger" href="https://m.me/SimBroadcasts">
          <DrawerItemIcon className="fab fa-facebook-messenger" />
          <DrawerItemText>Messenger</DrawerItemText>
        </DrawerItemA>

        <DrawerItemA key="dr_github" href="https://github.com/simbroadcasts">
          <DrawerItemIcon className="fab fa-github" />
          <DrawerItemText>GitHub</DrawerItemText>
        </DrawerItemA>

        <DrawerItemA key="dr_mail" href="mailto:contact@simbroadcasts.tv">
          <DrawerItemIcon className="far fa-envelope" />
          <DrawerItemText>Contact Us</DrawerItemText>
        </DrawerItemA>
      </DrawerItems>
    </StyledDrawer>
  );
};

export default Drawer;
