import styled from "styled-components";

const Filters = styled.form`
  /* border: solid 1px #dfdfdf; */
  /* margin-bottom: 25px; */
  margin: 10px 0px;
  height: 50px;
  line-height: 50px;
  /* background-color: #293143; */
  background-color: #1a1a1a;
  color: white;
  text-align: center;
  /* margin-top: 0px; */

  @media (prefers-color-scheme: light) {
    background-color: white;
  }

  button {
    @media (max-width: 500px) {
      display: none;
    }
  }

  i {
    float: left;
    height: 50px;
    line-height: 50px;
    margin-left: 15px;

    color: black;

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }

    @media (max-width: 500px) {
      display: none;
    }
  }

  label {
    color: black;

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }

    margin-right: 15px;

    @media (max-width: 695px) {
      display: none;
    }
  }

  select {
    appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path fill="black" d="M4 8L0 4h8z"/></svg>');
    padding: 0.5em;
    padding-right: 1.5em;
    border-radius: 0px;
    height: 40px;
    font-size: 1em;
    background-color: transparent;
    border-color: #606060;
    color: black;
    margin-right: 25px;
    width: 150px;

    @media (prefers-color-scheme: dark) {
      color: #fff;
      background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path fill="white" d="M4 8L0 4h8z"/></svg>');
    }

    &:disabled {
      border-color: #dfdfdf;
    }

    &:last-of-type {
      margin-right: 0px;
    }

    @media (max-width: 500px) {
      margin-right: 15px;
    }
  }

  option {
    background-color: #293143;
  }

  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`;

export default Filters;
