import React, { useRef, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import calendar from "../../calendar.json";

const StyledEvent = styled.article(
  ({ sim, env, sponsorshipLevel, sponsorshipMessage }) => css`
    background-image: url(assets/${sim}-environments/${env}.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 25px;
    ${sponsorshipLevel
      ? css`
          border: solid 0px #ffc400;
        `
      : null};
    box-sizing: border-box;
    ${"" /* min-height: 205px; */}

    @media (max-width: 500px) {
      margin-bottom: 10px;
    }
  `
);

const NextHeader = styled.div`
  height: 45px;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0px 5px;
`;

const NextUpHeading = styled.h2`
  color: white;
  font-size: 1.2em;
  text-transform: uppercase;
  margin: 0px 0px 0px 10px;
  font-weight: 700;
`;

const SeeFullBroadcastCalendar = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  /* background-color: #ffffff; */
  background-image: linear-gradient(to bottom, #ffffff, #ffffff, #dddddd);
  color: #000000;
  /* height: 35px; */
  /* line-height: 35px; */
  height: 45px;
  line-height: 45px;
  padding: 0px 10px;
  font-weight: 700;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #666;
  }

  i {
    margin-right: 10px;
  }

  span:nth-child(3) {
    display: none;
  }

  @media (max-width: 600px) {
    span:nth-child(3) {
      display: inline;
    }
    span:nth-child(2) {
      display: none;
    }
  }

  @media (max-width: 420px) {
    span:nth-child(2),
    span:nth-child(3) {
      display: none;
    }
    i {
      margin-right: 0px;
    }
    padding: 0px 15px;
  }
`;

const EventBody = styled.div`
  padding: 15px;
  position: relative;
  box-sizing: border-box;
  ${"" /* min-height: 160px; */}
  display: flex;

  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

const EventIcon = styled.div(
  ({ icon }) => css`
    background-image: url(assets/series-icons/${icon});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 130px;

    @media (max-width: 600px) {
      min-width: 100px;
    }

    @media (max-width: 420px) {
      height: 75px;
      max-width: 225px;
      background-size: contain;
      background-position: left;
      margin-bottom: 20px;
    }
  `
);

const EventDetails = styled.div`
  position: relative;
  border-left: solid 2px rgba(255, 255, 255, 0.5);
  margin-left: 15px;
  flex-grow: 1;
  padding-left: 15px;

  @media (max-width: 420px) {
    margin-left: 0px;
    border-left: none;
    padding: 0px;
  }
`;

const EventDetailsRow = styled.div`
  margin-top: 5px;
  display: flex;

  &:first-child {
    margin-top: 0px;
  }
`;

const EventDate = styled.div(
  ({ highlight, highContrast }) => css`
    display: inline-block;
    font-size: 1em;
    color: ${highContrast ? "#000" : "#fff"};
    background: ${highlight ? highlight : "#0d58a0"};
    font-weight: 700;
    padding: 5px 10px;
    margin-bottom: 10px;
    text-transform: uppercase;

    @media (max-width: 600px) {
      font-size: 0.8em;
    }
  `
);

const EventName = styled.div`
  display: inline-block;
  font-size: 1.2em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
  padding: 5px 10px;
  text-transform: uppercase;
`;

const EventCombination = styled.div`
  display: inline-block;
  font-size: 1em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
  padding: 5px 10px;
  text-transform: uppercase;
`;

const EventLink = styled.a(
  ({ platform }) => css`
    text-decoration: none;
    /* background-color: #ffffff; */
    background-image: linear-gradient(to bottom, #ffffff, #ffffff, #dddddd);
    color: #000000;
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    padding: 5px 10px;
    text-transform: uppercase;
    margin-top: 10px;
    margin-right: 5px;
    ${"" /* transition: all 0.2s ease-in-out; */}

    &:hover {
      color: #ffffff;
      ${platform === undefined &&
      "background: linear-gradient(to bottom, #111111, #111111, #444444)"}
      ${platform === "youtube" &&
      "background: linear-gradient(to bottom, #ff0000, #ff0000, #dd0000)"}
      ${platform === "twitch" &&
      "background: linear-gradient(to bottom, #9146ff, #9146ff, #6917e0)"};
    }

    i {
      /* margin-right: 10px; */
    }

    &:last-child {
      margin-right: 0px;
    }

    @media (max-width: 800px) {
      span {
        display: none;
      }
      i {
        margin: 0;
      }
    }
  `
);

const SimIcon = styled.a(
  ({ icon }) => css`
    background-image: url(assets/sim-icons/${icon}.png);
    width: ${icon === "lfs" ? "75px" : "32px"};
    height: 25px;
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    background-position: center;
    top: 15px;
    right: 15px;
  `
);

// const EventType = styled.div`
//   position: absolute;
//   bottom: 0px;
//   right: 0px;
//   font-size: 1.6em;
//   color: #fff;
//   text-transform: uppercase;
//   font-weight: 700;

//   @media (max-width: 900px) {
//     margin-bottom: 30px;
//   }

//   @media (max-width: 700px) {
//     font-size: 1.3em;
//   }
// `;

const StyledSponsorship = styled.div`
  display: inline-block;
  /* font-size: 0.8em; */
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.6);
  /* background: #ffc400; */
  font-weight: 700;
  padding: 15px 10px 0px 0px;
  width: 100%;
  text-transform: uppercase;
  /* margin-left: 5px; */

  i {
    margin: 0px 4px 0px 4px;
  }
`;

const SponsorshipInfoLink = styled(Link)`
  color: rgba(255, 255, 255, 0.5);
`;

const Sponsorship = ({ sponsorship }) => {
  let tierMsg = "Sponsored by Sim Broadcasts";

  if (sponsorship) {
    switch (sponsorship.level) {
      case 1:
        tierMsg = "Sponsored by Sim Broadcasts";
        break;
      case 2:
        tierMsg = `Paid contribution from ${sponsorship.sponsor}`;
        break;
      case 3:
        tierMsg = `Funded by ${sponsorship.sponsor}`;
        break;
      default:
        tierMsg = "Sponsored by Sim Broadcasts";
        break;
    }
  }

  return (
    <StyledSponsorship>
      <i className="fas fa-handshake"></i>
      {/* Tier {sponsorship.level} Sponsorship <i className="fas fa-handshake"></i>{" "} */}
      {tierMsg}
      {" - "}
      <SponsorshipInfoLink to="funding">What's this?</SponsorshipInfoLink>
    </StyledSponsorship>
  );
};

// const MiniSeriesLogo = styled.div(({ hc, event }) => {
//   return css`
//     display: none;
//     width: 21px;
//     height: 21px;
//     background-image: url(../assets/series-icons/${event});
//     background-repeat: no-repeat;
//     background-size: 21px;
//     background-position: center;
//     padding: 2px;
//     margin-right: 5px;
//     ${"" /* background-color: ${hc ? "black" : "white"}; */}
//     background-color: black;

//     @media (prefers-color-scheme: light) {
//       background-color: #2e3f67;
//       border-radius: 4px;
//     }

//     @media (max-width: 420px) {
//       display: block;
//     }
//   `;
// });

const timeUntilTimestamp = (t) => {
  // Get time now
  const now = new Date().getTime() / 1000;

  // Between now and epoch
  var diff = t - now <= 0 ? 0 : t - now;

  // Days remaining
  const days = Math.floor(diff / 86400);
  diff -= days * 86400;

  // Hours remaining
  const hours = Math.floor(diff / 3600);
  diff -= hours * 3600;

  // Minutes remaining
  const minutes = Math.floor(diff / 60);
  diff -= minutes * 60;

  // Seconds remaining
  const seconds = Math.floor(diff);

  return { days, hours, minutes, seconds };
};

const formatTimeString = (t, s) => {
  const pluralDays = t.days !== 1;
  const pluralHours = t.hours !== 1;
  const pluralMinutes = t.minutes !== 1;
  const pluralSeconds = t.seconds !== 1;
  const daysString = s
    ? `${t.days}D `
    : `${t.days} day${pluralDays ? "s " : ""}`;
  const hoursString = s
    ? `${t.hours}H `
    : `${t.hours} hour${pluralHours ? "s " : ""}`;
  const minutesString = s
    ? `${t.minutes}M `
    : `${t.minutes} minute${pluralMinutes ? "s " : ""}`;
  const secondsString = s
    ? `${t.seconds}S `
    : `${t.seconds} second${pluralSeconds ? "s" : ""}`;
  // return `${daysString} ${hoursString} ${minutesString} ${secondsString}`;
  return `${t.days === 0 ? "" : daysString}${
    t.hours === 0 ? "" : hoursString
  }${minutesString}${secondsString}`;
};

const StyledTimeUntilEvent = styled.span``;

const TimeUntilEvent = ({ time }) => {
  return <StyledTimeUntilEvent>in {time}</StyledTimeUntilEvent>;
};

const Event = (props) => {
  let event = props.event;
  let series = calendar.series.find((el) => el.name === event.series);
  let icon = series.icon;
  let date = event.date_string;
  let name = `${series.organiser} ${series.series} - ${event.name}`;
  let combination = `${event.track} - ${event.car}`;
  let seriesWebsite = series.website;
  let broadcast = series.broadcast;
  let sim = series.sim;
  // let eventType = event.type;
  let highlight = series.highlight_colour;
  let highContrast = series.high_contrast;
  let sponsorship = series.sponsorship;

  let env;

  const nextEventTimeString = (t, s) => {
    return formatTimeString(timeUntilTimestamp(t), s);
  };

  const savedInterval = useRef();
  const [remainingTime, setRemainingTime] = useState(
    nextEventTimeString(event.date_uts, true)
  );

  switch (sim) {
    case "lfs": {
      env = event.track.slice(0, 2).toLowerCase();
      const lfsenvs = [
        "bl",
        "fe",
        "au",
        "ky",
        "we",
        "au",
        "so",
        "ro",
        "as",
        "la",
      ];
      // If the event is on a custom configuration
      if (!lfsenvs.includes(env) && event.track !== "tbc") {
        env = "custom";
      } else if (event.track === "tbc") {
        env = "tbc";
      }
      break;
    }
    case "iracing": {
      env = event.track.split(" ")[0].toLowerCase();
      const irenvs = ["knockhill", "nurburgring", "rudskogen", "road"];
      if (!irenvs.includes(env) && event.track !== "tbc") {
        env = "placeholder";
      } else if (event.track === "tbc") {
        env = "tbc";
      }
      // console.log(env, sim);
      break;
    }
    default:
      break;
  }

  useEffect(() => {
    savedInterval.current = () =>
      setRemainingTime(nextEventTimeString(event.date_uts, true));
  });

  useEffect(() => {
    const cb = () => {
      if (savedInterval.current !== undefined) savedInterval.current();
    };
    let interval = setInterval(cb, 1000);
    return () => clearInterval(interval, cb);
  }, []);

  return (
    <StyledEvent sim={sim} env={env} sponsorship={sponsorship}>
      {props.id === 0 ? (
        <NextHeader>
          <NextUpHeading>
            Next Up{" "}
            {remainingTime !== "" ? (
              <TimeUntilEvent time={remainingTime} />
            ) : null}
          </NextUpHeading>
          {props.cal ? (
            <SeeFullBroadcastCalendar to="calendar">
              <i className="far fa-calendar"></i>
              <span>Broadcast calendar</span>
              <span>calendar</span>
            </SeeFullBroadcastCalendar>
          ) : (
            <SeeFullBroadcastCalendar to="calendar/archive">
              <i className="far fa-archive"></i>
              <span>Broadcast archive</span>
              <span>archive</span>
            </SeeFullBroadcastCalendar>
          )}
        </NextHeader>
      ) : null}
      <EventBody>
        <EventIcon icon={icon} />
        <EventDetails>
          <EventDetailsRow>
            {/* <MiniSeriesLogo event={icon} sim={sim} hc={highContrast} /> */}
            <EventDate highlight={highlight} highContrast={highContrast}>
              {date}
            </EventDate>
          </EventDetailsRow>
          <EventDetailsRow>
            <EventName>{name}</EventName>
          </EventDetailsRow>
          <EventDetailsRow>
            <EventCombination>{combination}</EventCombination>
          </EventDetailsRow>
          <EventDetailsRow>
            <EventLink href={seriesWebsite}>
              <i className="far fa-info-circle"></i>
              <span> Series Info</span>
            </EventLink>
            <>
              {broadcast.map((el, i) => (
                <EventLink
                  platform={el.platform}
                  key={`${el.series}${el.name}${el.platform}`}
                  href={`https://${el.platform}${
                    el.platform === "twitch" ? ".tv" : ".com"
                  }/${el.channel}${el.platform === "youtube" ? "/live" : ""}`}
                >
                  {/* <i className={`fab fa-${el.platform}`}></i>/{el.channel} */}
                  <i className={`fab fa-${el.platform}`}></i>
                </EventLink>
              ))}
            </>
          </EventDetailsRow>
          <Sponsorship sponsorship={sponsorship} />
          {/* <EventType>{eventType}</EventType> */}
        </EventDetails>
        <SimIcon
          icon={sim}
          target="_blank"
          href={sim === "lfs" ? "https://lfs.net" : "https://iracing.com"}
        />
      </EventBody>
    </StyledEvent>
  );
};

export default Event;
