import { css } from "styled-components";

const PageHeroBackground = (image) => css`
  background-image: url(${image});
  background-color: rgb(74 87 119);
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export default PageHeroBackground;
