import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  Main,
  BroadcastStatusBar,
  Event,
  RequestBookingBanner,
  Filters,
  PageHeroBackground,
} from "./components";
import { useLocation } from "react-router-dom";
import calendar from "../calendar.json";
import { Helmet } from "react-helmet";
import { heat } from "../assets/hero/pages";

const Hero = styled.div`
  width: 100%;
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  color: #ffffff;
  text-align: center;
  height: 150px;
  line-height: 150px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  ${PageHeroBackground(heat)}

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #aaaaaa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 550px) {
    font-size: 2.2em;
  }

  @media (max-width: 500px) {
    font-size: 2em;
    height: 100px;
    line-height: 100px;
    /* margin-bottom: 10px; */
  }

  @media (max-width: 400px) {
    font-size: 1.7em;
  }
`;

const NoEvents = styled.p`
  text-align: center;
  font-size: 1em;

  button {
    appearance: none;
    border: none;
    font-size: 1em;
    color: #333;
    background-color: transparent;
    padding: 0px;
    text-decoration: underline;
    cursor: pointer;

    @media (prefers-color-scheme: dark) {
      color: #ddd;
    }
  }

  @media (prefers-color-scheme: dark) {
    color: #ddd;
  }
`;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetFilters = styled.button(
  ({ simFilter, seriesFilter }) => css`
    ${
      "" /* visibility: ${simFilter !== "all" || seriesFilter !== "all"
      ? "visible"
      : "hidden"}; */
    }
    opacity: ${simFilter !== "all" || seriesFilter !== "all" ? 1 : 0.5};
    user-select: ${simFilter !== "all" || seriesFilter !== "all"
      ? "auto"
      : "none"};
    pointer-events: ${simFilter !== "all" || seriesFilter !== "all"
      ? "auto"
      : "none"};
    appearance: none;
    border: none;
    font-size: 1em;
    background-color: transparent;
    padding: 0px;
    text-decoration: underline;
    cursor: pointer;
    float: right;
    color: white;
    margin-right: 15px;

    i {
      opacity: 1;
      margin-left: 0px;
    }
  `
);

const BroadcastArchiveTextButton = styled(Link)`
  color: inherit;
`;

function Calendar() {
  const query = useQuery();
  const [simFilter, setSimFilter] = useState(query.get("sim") || "all");
  const [seriesFilter, setSeriesFilter] = useState(
    query.get("series") || "all"
  );

  console.log(useQuery());
  const [upcoming, setUpcoming] = useState([]);
  const [upcomingFiltered, setUpcomingFiltered] = useState([]);

  useEffect(() => {
    let upcomingEvents = [];
    let upcomingEventsFiltered = [];

    calendar.events.forEach((el) => {
      if (el.date_uts > Date.now() / 1000) {
        upcomingEvents.push(el);
        if (
          simFilter === "all" ||
          simFilter === calendar.series.find((s) => s.name === el.series).sim
        ) {
          if (
            seriesFilter === "all" ||
            seriesFilter ===
              calendar.series.find((s) => s.name === el.series).name
          ) {
            upcomingEventsFiltered.push(el);
          }
        }
      }
    });

    setUpcoming(upcomingEvents);
    setUpcomingFiltered(upcomingEventsFiltered);
  }, [simFilter, seriesFilter]);

  const handleSimSelectChange = (e) => {
    e.persist();
    setSimFilter(e.target.value);
    setSeriesFilter("all");
  };

  const handleSeriesSelectChange = (e) => {
    e.persist();
    setSeriesFilter(e.target.value);
    setSimFilter("all");
  };

  const resetFilters = (e) => {
    e.preventDefault();
    setSimFilter("all");
    setSeriesFilter("all");
  };

  const seriesUnique = new Set([...upcoming.map((el) => el.series)]);
  const simsUnique = new Set([
    ...Array.from(seriesUnique).map(
      (el) => calendar.series.find((s) => s.name === el).sim
    ),
  ]);

  const supportedSims = { lfs: "Live for Speed", iracing: "iRacing" };

  const simSelectOptions = Array.from(simsUnique).map((el) => {
    return (
      <option key={el} value={el}>
        {supportedSims[el]}
      </option>
    );
  });

  const seriesSelectOptions = Array.from(seriesUnique).map((el) => {
    let series = calendar.series.find((s) => s.name === el);
    return (
      <option key={el} value={el}>
        {series.organiser} {series.series}
      </option>
    );
  });

  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | Broadcast Calendar</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>Broadcast Calendar</h1>
      </Hero>
      <Filters>
        <i className="far fa-filter"></i>
        <label htmlFor="seriesselect">Filter by series</label>
        <select
          value={seriesFilter}
          name="seriesselect"
          id="seriesselect"
          onChange={handleSeriesSelectChange}
        >
          <option key="showallseries" value="all">
            Show all series
          </option>
          <>{seriesSelectOptions}</>
        </select>
        <label htmlFor="simselect">Filter by sim</label>
        <select
          value={simFilter}
          name="simselect"
          id="simselect"
          onChange={handleSimSelectChange}
        >
          <option key="showallsims" value="all">
            Show all sims
          </option>
          <>{simSelectOptions}</>
        </select>
        <ResetFilters
          onClick={resetFilters}
          simFilter={simFilter}
          seriesFilter={seriesFilter}
        >
          <i className="far fa-undo"></i>
        </ResetFilters>
      </Filters>
      {upcomingFiltered.map((el, i) => (
        <Event event={el} id={i} key={`event${i}`} />
      ))}
      {upcoming.length !== 0 && upcomingFiltered.length === 0 ? (
        <NoEvents>
          No broadcasts to show -{" "}
          <button onClick={resetFilters}>Reset filters</button>
        </NoEvents>
      ) : null}
      {upcoming.length === 0 ? (
        <NoEvents>
          No upcoming broadcasts. See{" "}
          <BroadcastArchiveTextButton to="calendar/archive">
            broadcast archive
          </BroadcastArchiveTextButton>
          .
        </NoEvents>
      ) : null}
      <RequestBookingBanner />
    </Main>
  );
}

export default Calendar;
