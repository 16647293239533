import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Main, BroadcastStatusBar, PageHeroBackground } from "./components";
import { Helmet } from "react-helmet";
import { westhillLongRange } from "../assets/hero/pages";

const Hero = styled.div`
  width: 100%;
  margin-bottom: 25px;
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  color: #ffffff;
  text-align: center;
  height: 150px;
  line-height: 150px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  ${PageHeroBackground(westhillLongRange)}

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #aaaaaa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 630px) {
    font-size: 2em;
    height: 100px;
    line-height: 100px;
    margin-bottom: 10px;
  }

  @media (max-width: 500px) {
    font-size: 1.7em;
    margin-bottom: 10px;
  }

  @media (max-width: 410px) {
    font-size: 1.4em;
  }
`;

const TextPane = styled.article`
  padding: 25px;

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }

  a {
    color: #333;

    @media (prefers-color-scheme: dark) {
      color: #bbb;
    }
  }

  h2 {
    text-transform: uppercase;
    margin-top: 50px;

    &:first-child {
      margin-top: 0px;
    }

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  h3 {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-style: italic;
    color: #555;

    & + p {
      margin-top: 5px;
    }
  }

  i {
    margin-right: 5px;
    color: #555;
  }

  form {
    * {
      box-sizing: border-box;
    }
    input,
    label,
    select,
    textarea {
      display: block;
      width: 100%;
    }

    input {
      &[type="radio"] {
        height: 15px;
        width: 15px;
        display: none;

        & + label {
          text-align: center;
          padding: 10px;
          height: 70px;
          background-color: #efefef;
          cursor: pointer;
          position: relative;
          margin-bottom: 0px;
          opacity: 1;
          transition: opacity 0.2s ease-in-out;

          &:hover {
            opacity: 0.75;
          }

          &::after {
            position: absolute;
            bottom: 10px;
            left: calc(50% - 50px);
            width: 100px;
            font-weight: bold;
            text-transform: uppercase;
            content: "Choose";
          }

          @media (max-width: 700px) {
            ${"" /* font-size: 0.8em; */}
          }

          @media (prefers-color-scheme: dark) {
            background-color: #333;
          }
        }

        &:checked + label {
          background-color: #0082ff;
          color: #fff;
          &::after {
            position: absolute;
            bottom: 10px;
            left: calc(50% - 50px);
            width: 100px;
            font-weight: bold;
            text-transform: uppercase;
            content: "Selected";
          }
        }
      }
      &[type="text"],
      &[type="email"],
      &[type="url"] {
        height: 40px;
        padding: 8px;
        font-size: 1em;
        border: solid 1px #dfdfdf;

        @media (prefers-color-scheme: dark) {
          border-color: #444;
        }
      }

      &[type="submit"] {
        height: 40px;
        padding: 8px;
        font-size: 1em;
        border: none;
        background-color: #0082ff;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;

        &:hover {
          opacity: 0.75;
        }
      }

      @media (prefers-color-scheme: dark) {
        background-color: #222;
        color: #fff;
      }
    }

    select:not([multiple]) {
      appearance: none;
      background-position: right 50%;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
      padding: 0.5em;
      padding-right: 1.5em;
      border-radius: 0px;
      height: 40px;
      font-size: 1em;
      background-color: transparent;
      border-color: #dfdfdf;

      &:disabled {
        border-color: #dfdfdf;
      }

      @media (prefers-color-scheme: dark) {
        color: #fff;
        border-color: #444;
      }
    }

    h3 {
      margin-top: 40px;
      margin-bottom: 15px;
      padding-top: 20px;
      border-top: solid 1px #dfdfdf;

      @media (prefers-color-scheme: dark) {
        border-color: #444;
      }

      &:first-child {
        margin-top: 0px;
        border: none;
      }
    }

    textarea {
      border-color: #dfdfdf;
      min-height: 100px;
      padding: 8px;
      font-size: 1em;

      @media (prefers-color-scheme: dark) {
        background-color: #222;
        border-color: #444;
        color: #fff;
      }
    }

    label {
      margin-bottom: 5px;
    }

    label + p {
      margin: 0px;
      font-size: 0.9em;
      color: #555;
      margin-bottom: 5px;

      @media (prefers-color-scheme: dark) {
        color: #ddd;
      }
    }
  }

  p,
  li {
    line-height: 1.5em;
  }

  li:nth-child(6),
  li:nth-child(7) {
    color: #999;
    &::after {
      content: " - Coming Soon";
    }
  }
`;

const Form = styled.form`
  margin-top: 30px;
`;

const FormItem = styled.div`
  flex: 1;
  margin: 0px 10px;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  @media (max-width: 500px) {
    margin: 0px 0px 30px 0px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const FormRow = styled.div`
  display: flex;
  margin-bottom: 30px;

  @media (max-width: 700px) {
    &.radios div:nth-child(2) {
      margin: 0px;
    }
  }

  @media (max-width: 650px) {
    &.radios {
      flex-direction: column;

      & div:nth-child(2) {
        margin: 0px 0px 10px 0px;
      }

      & div {
        margin: 0px 0px 10px 0px;
      }
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

function About() {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  function sendData(e) {
    e.stopPropagation();
    e.preventDefault();
    e.persist();

    const data = Object.fromEntries(new FormData(e.target).entries());

    fetch("https://live.simbroadcasts.tv/api/v1/bookingmailer", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type: "booking", data }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setSending(false);
        setSent(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setSending(true);
  }

  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | Request Your Broadcast</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>Request Your Broadcast</h1>
      </Hero>
      {!sent ? (
        <TextPane>
          <h2>Tell us what you need</h2>
          <p>
            To request a broadcast, we need to know a little bit about you and
            the event you are planning. We are limited as to the number of
            broadcasts we can run concurrently so we ask that you please check
            for conflicts on our <Link to="calendar">Broadcast Calendar</Link>{" "}
            before submitting your request.
          </p>
          {/* <p>
            If you are running a corporate event or series, please see our{" "}
            <Link to="corporate">Corporate Services Brochure</Link> for prices
            and a list of what other services we can offer you and your brand.
          </p> */}
          <Form onSubmit={!sending ? sendData : null}>
            <h3>Step 1: Basic Information</h3>
            <FormRow>
              <FormItem>
                <label htmlFor="forename">Forename</label>
                <input
                  type="text"
                  id="forename"
                  name="forename"
                  placeholder="John"
                  required
                />
              </FormItem>
              <FormItem>
                <label htmlFor="surname">Surname</label>
                <input
                  type="text"
                  id="surname"
                  name="surname"
                  placeholder="Smith"
                  required
                />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <label htmlFor="email">Email Address</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="john.smith@example.com"
                  required
                />
              </FormItem>
              <FormItem>
                <label htmlFor="sim">Simulator</label>
                <select id="sim" name="sim" required>
                  <option value="lfs" default>
                    Live for Speed
                  </option>
                  <option value="iracing" default>
                    iRacing
                  </option>
                </select>
              </FormItem>
            </FormRow>
            <h3>Step 2: Services You Require</h3>
            <FormRow className="radios">
              <FormItem>
                <input
                  type="radio"
                  id="servicecommentary"
                  name="service"
                  value="0"
                />
                <label htmlFor="servicecommentary">Commentary Only</label>
              </FormItem>
              <FormItem>
                <input
                  type="radio"
                  id="servicestream"
                  name="service"
                  value="1"
                />
                <label htmlFor="servicestream">Stream Only</label>
              </FormItem>
              <FormItem>
                <input
                  type="radio"
                  id="servicefull"
                  name="service"
                  value="2"
                  defaultChecked
                />
                <label htmlFor="servicefull">Both (Full Broadcast)</label>
              </FormItem>
            </FormRow>
            <h3>Step 3: Event/Series Details</h3>
            <FormRow>
              <FormItem>
                <label htmlFor="eventname">Event/Series Name</label>
                <input
                  type="text"
                  id="eventname"
                  name="eventname"
                  placeholder="8 Hours of Westhill"
                  required
                />
              </FormItem>
              <FormItem>
                <label htmlFor="website">Website/Forum (optional)</label>
                <input
                  id="website"
                  name="website"
                  type="url"
                  placeholder="https://westhill8hour.com"
                />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <label htmlFor="description">About Your Event</label>
                <p>
                  Tell us a bit about your event and why you are hosting it.
                </p>
                <textarea
                  id="description"
                  name="description"
                  placeholder=""
                  required
                />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <label htmlFor="schedule">Provisional Schedule</label>
                <p>
                  Please include as much information as possible including:
                  Times and dates, cars and tracks, event formats etc.
                </p>
                <textarea
                  id="schedule"
                  name="schedule"
                  placeholder=""
                  required
                />
              </FormItem>
            </FormRow>
            <p>
              We cannot guarantee that we will be able to accommodate all
              requests but we will endeavour to do so if we have the resources
              available.
            </p>
            {!sent ? (
              <input
                type="submit"
                value={!sending ? "Request Broadcast" : "Sending..."}
              />
            ) : null}
          </Form>
        </TextPane>
      ) : (
        <TextPane>
          <h2>Thank You!</h2>
          <h3>Your booking request has been sent.</h3>
          <p>
            If you do not hear back from one of our team within 2 business days
            please <a href="mailto:contact@simbroadcasts.tv">contact us</a>.
          </p>
        </TextPane>
      )}
    </Main>
  );
}

export default About;
