import React from "react";
import styled from "styled-components";
import {
  Main,
  BroadcastStatusBar,
  RequestBookingBanner,
  PageHeroBackground,
} from "./components";
import { Helmet } from "react-helmet";
import { gt3Downhill } from "../assets/hero/pages";

const Hero = styled.div`
  width: 100%;
  margin-bottom: 25px;
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  color: #ffffff;
  text-align: center;
  height: 150px;
  line-height: 150px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  ${PageHeroBackground(gt3Downhill)}

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #aaaaaa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 500px) {
    font-size: 2em;
    height: 100px;
    line-height: 100px;
    margin-bottom: 10px;
  }
`;

const TextPane = styled.article`
  padding: 25px;

  a {
    color: #333;

    &[name] {
      text-decoration: none;
      display: block;

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    h2 {
      position: relative;
      &::before {
        position: absolute;
        left: -1.5em;
        padding-right: 1.5em;
        line-height: 1.6em;
        font-size: 0.7em;
        font-family: "Font Awesome 5 Pro";
        content: "\uf0c1";
        color: #bbb;
        opacity: 0;
        user-select: none;
        cursor: normal;
      }

      &:hover::before {
        opacity: 1;
      }
    }
  }

  h2 {
    text-transform: uppercase;
    margin-top: 50px;

    &:first-child {
      margin-top: 0px;
    }

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-style: italic;
    color: #555;

    & + p {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }

  i {
    margin-right: 5px;
    color: #555;
  }

  p,
  li {
    line-height: 1.5em;
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const Button = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  background-color: #0082ff;
  color: #ffffff;
  height: 35px;
  line-height: 35px;
  padding: 0px 15px;
  font-weight: 700;
  margin-right: 15px;
  flex-shrink: 0;
  box-sizing: border-box;
  flex: 1 1;
  text-align: center;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.75;
  }

  &:last-child {
    margin-right: 0px;
  }

  span {
    color: #ffffff;
  }

  i {
    color: #ffffff;
    margin-right: 10px;
  }

  @media (max-width: 650px) {
    flex: 0 0 100%;
    margin: 0px;
    margin-bottom: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
`;

const CSB = styled.div`
  display: flex;

  @media (max-width: 900px) {
    display: block;
  }
`;

const CSBCover = styled.div`
  text-align: center;
`;

const CSBCoverImage = styled.img`
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.4);
  height: 275px;

  @media (max-width: 900px) {
    height: 200px;
  }
`;

const CSBDownload = styled.div`
  padding: 25px 50px;
  box-sizing: border-box;
  height: 100%;

  @media (max-width: 900px) {
    padding: 0px;
    margin-top: 50px;
  }
`;

function About() {
  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | Corporate Services</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>Corporate Services</h1>
      </Hero>
      <TextPane>
        <CSB>
          <CSBCover>
            <CSBCoverImage src="assets/csb/sbtv-csb-cover.jpg" />
          </CSBCover>
          <CSBDownload>
            <a
              name="corporate-services-brochure"
              href="#corporate-services-brochure"
            >
              <h2>Corporate Services Brochure</h2>
            </a>
            <p>
              If you are looking to run a sim racing event or series, we have
              you covered. We have vast experience of running sim racing events
              and broadcasts for commercial and non-commercial organisations. We
              will work with you to create a bespoke broadcast package, placing
              your brand front and centre.
            </p>
            <ButtonGroup>
              <Button
                href="downloads/sbtv-corporate-services-brochure.pdf"
                target="_blank"
              >
                <i className="far fa-download"></i>{" "}
                <span>Download Brochure (8.3MB)</span>
              </Button>
            </ButtonGroup>
          </CSBDownload>
        </CSB>
      </TextPane>
      <RequestBookingBanner />
    </Main>
  );
}

export default About;
