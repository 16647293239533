import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

const StyledBroadcastStatusBar = styled.div`
  height: 45px;
  /* background-color: #ffc400; */
  background: linear-gradient(to bottom, #ffc400, #ffc400, #d5a300);
`;

const Status = styled.div`
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TuneIn = styled.a(
  ({ platform }) => css`
    text-transform: uppercase;
    text-decoration: none;
    /* background-color: #ffffff; */
    ${platform === "youtube" &&
    "background: linear-gradient(to bottom, #ff0000, #ff0000, #dd0000)"}
    ${platform === "twitch" &&
    "background: linear-gradient(to bottom, #9146ff, #9146ff, #6917e0)"};

    /* color: #000000; */
    color: white;
    /* height: 35px; */
    /* line-height: 35px; */
    height: 45px;
    line-height: 45px;
    padding: 0px 15px;
    font-weight: 700;
    /* margin-right: 5px; */
    margin-right: 0px;
    /* margin-left: 15px; */
    flex-shrink: 0;
    transition: color 0.2s ease-in-out;
    /* font-size: 1.2em; */

    &:first-of-type {
      margin-left: 15px;
    }

    &:hover {
      color: rgba(255, 255, 255, 0.75);
    }

    i {
      margin-right: 10px;
    }
  `
);

const Bullet = styled.div(
  ({ live }) => css`
    width: 15px;
    height: 15px;
    background-color: ${live ? "#ff0000" : "#000000"};
    border-radius: 50%;
    margin: 15px;
    flex-shrink: 0;
  `
);

const StatusMessage = styled.h1`
  margin: 0px;
  font-size: 1.5em;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
  min-width: 0px;
  text-overflow: ellipsis;
`;

const StreamTitle = styled.span`
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 5px;
`;

const BroadcastStatusBar = (props) => {
  const [live, setLive] = useState(false);
  const [title, setTitle] = useState("");

  let broadcastTwitch = "https://twitch.tv/simbroadcasts";
  let broadcastYouTube = "https://youtube.com/simbroadcasts/live";
  let channel = "simbroadcasts";

  // let id = 1;
  // let event = calendar.events[id];
  // let series = calendar.series[event.series];

  useEffect(() => {
    fetch(`https://live.simbroadcasts.tv/api/v1/twitchstatus`, {
      method: "GET",
    }).then((res) =>
      res.json().then((json) => {
        if (json) {
          if (json.online) {
            setLive(true);
            setTitle(json.title);
          } else {
            setLive(false);
            setTitle("");
          }
        } else {
          setTitle("");
          setLive(false);
        }
      })
    );
  }, [channel]);

  return (
    <StyledBroadcastStatusBar>
      {live ? (
        <Status>
          <Bullet live={live} />
          <StatusMessage>
            LIVE NOW: <StreamTitle>{title}</StreamTitle>
          </StatusMessage>
          <TuneIn platform="twitch" href={broadcastTwitch}>
            <i className="fab fa-twitch"></i>
            <span>Twitch</span>
          </TuneIn>
          <TuneIn platform="youtube" href={broadcastYouTube}>
            <i className="fab fa-youtube"></i>
            <span>YouTube</span>
          </TuneIn>
        </Status>
      ) : (
        <Status>
          <Bullet live={live} />
          <StatusMessage>OFFLINE</StatusMessage>
          <TuneIn
            platform="youtube"
            href="https://www.youtube.com/simbroadcasts"
          >
            <i className="fab fa-youtube"></i>
            <span>Past broadcasts</span>
          </TuneIn>
        </Status>
      )}
    </StyledBroadcastStatusBar>
  );
};

export default BroadcastStatusBar;
